import Card from './Card';
import APP_IMAGES from '../../constants/appImages';

const BitverxWork = () => {
  const desktop = (
    <section className="w-full hidden lg:block">
      <div className="max-w-[1200px] mx-auto sm:py-36 py-10 px-6">
        <div className="text-center sm:mb-12 md:mb-16">
          <h2 className="font-semibold text-[24px] leading-[26px] sm:text-5xl sm:leading-[72.72px] mb-4 relative">
            Software Development
            <span className="ml-2 inline-block after:hidden sm:after:block after:absolute after:-right-5 after:bottom-2 sm:after:right-48 sm:after:bottom-1 after:-z-10 after:bg-primary after:w-[93.85px] after:h-1 sm:after:w-[221px] sm:after:h-2">
              Process
            </span>
          </h2>
        </div>
        <div className="flex flex-col items-center">
          <div className="flex sm:flex-row flex-col justify-between items-center sm:mb-14 gap-8">
            <Card
              title="Assemble the right team"
              description="We pick the right expertise, domain knowledge, and willingness to adapt and evolve to your project requirement."
              imageText="Assemble the right team"
              imageUrl={APP_IMAGES.assemble}
            />
            <Card
              title="Development methodology planning"
              description="Choose the suitable methodology that empowers the development process to be conducted at its best. Our team members follow sprint planning and agile techniques."
              imageText="Development methodology planning"
              imageUrl={APP_IMAGES.sprintPlanning}
            />
            <Card
              title="Architecture creation"
              description="We handle all architecture evolution requirements ranging from UI/UX enhancement, decoupling tightly packed features by migrating to microservices or modularizing the existing architecture."
              imageText="Architecture creation"
              imageUrl={APP_IMAGES.techarchitechture}
            />
          </div>

          {/* SVG Section */}
          <div className="w-full">
            <svg
              viewBox="0 0 944 115"
              preserveAspectRatio="xMidYMid meet"
              className="w-full h-auto"
            >
              <g id="Rectangle1" transform="translate(0.000000, 0.000000)">
                <path
                  d="M886.4,114.6H57.6c-31.5,0-57-26.6-57-56.5l0,0c0-29.9,25.5-57.5,57-57.5h828.9c31.5,0,57,27.6,57,57.5l0,0 C943.4,87.9,918,114.6,886.4,114.6z"
                  id="theMotionPath"
                  fill="#fff"
                  fillRule="nonzero"
                  stroke="#DCE6FB0"
                  strokeWidth="1"
                ></path>
              </g>
              <g id="Rectan" transform="translate(0.000000, 0.000000)">
                <path
                  d="M879.9,107.1H64.1c-31.5,0-57-19.1-57-49l0,0c0-29.9,25.5-50,57-50h815.9c31.5,0,57,20.1,57,50l0,0  C936.9,87.9,911.5,107.1,879.9,107.1z"
                  id="theMotionPath1"
                  fill="#fff"
                  fillRule="nonzero"
                  stroke="#59a1e5"
                  strokeWidth="2"
                ></path>
              </g>
              {/* Circles and Animation */}
              <circle
                cx="471.5"
                cy="7.5"
                r="6.5"
                fill="#FFF"
                stroke="#0085ff"
                strokeLinecap="round"
                strokeWidth="2"
              ></circle>
              <circle
                cx="141.5"
                cy="7.5"
                r="6.5"
                fill="#FFF"
                stroke="#0085ff"
                strokeLinecap="round"
                strokeWidth="2"
              ></circle>
              <circle
                cx="801.5"
                cy="7.5"
                r="6.5"
                fill="#FFF"
                stroke="#0085ff"
                strokeLinecap="round"
                strokeWidth="2"
              ></circle>
              <circle
                cx="141.5"
                cy="107.5"
                r="6.5"
                fill="#FFF"
                stroke="#0085ff"
                strokeLinecap="round"
                strokeWidth="2"
              ></circle>
              <circle
                cx="471.5"
                cy="107.5"
                r="6.5"
                fill="#FFF"
                stroke="#0085ff"
                strokeLinecap="round"
                strokeWidth="2"
              ></circle>
              <circle
                cx="801.5"
                cy="107.5"
                r="6.5"
                fill="#FFF"
                stroke="#0085ff"
                strokeLinecap="round"
                strokeWidth="2"
              ></circle>
              <animateMotion
                xlinkHref="#plane"
                dur="30s"
                repeatCount="indefinite"
                rotate="auto"
              >
                <mpath xlinkHref="#theMotionPath"></mpath>
              </animateMotion>
              <g
                id="plane"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
              >
                <g
                  id="Home"
                  transform="translate(-548.000000, -10940.000000)"
                  fill="#59a1e5"
                  fillRule="nonzero"
                >
                  <g id="process" transform="translate(0.000000, 10548.000000)">
                    <g
                      id="Group-17"
                      transform="translate(247.000000, 145.000000)"
                    >
                      <g
                        id="Process_linebar"
                        transform="translate(0.000000, 7.000000)"
                      >
                        <path
                          d="M301.839209,248.888889 L301.839209,246.888889 L301.839209,241.724004 C301.839209,241.262783 302.213103,240.888889 302.674325,240.888889 C302.803972,240.888889 302.931839,240.919074 303.0478,240.977054 L315.377386,247.141848 C315.789915,247.348112 315.957126,247.849743 315.750861,248.262272 C315.670052,248.42389 315.539004,248.554938 315.377386,248.635747 L303.0478,254.80054 C302.635271,255.006805 302.133639,254.839595 301.927375,254.427066 C301.869395,254.311105 301.839209,254.183238 301.839209,254.053591 L301.839209,248.888889 Z"
                          id="Line"
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </div>

          <div className="flex sm:flex-row flex-col justify-between items-center sm:mt-14 gap-8">
            <Card
              title="Periodical reviews"
              description="We conduct timely reviews to squash any foreseeable concern, present weekly demos, and standups to address any existing bottlenecks to ensure everyone is on the same page."
              imageText="Periodical reviews"
              imageUrl={APP_IMAGES.iterativeDelivery}
            />
            <Card
              title="Intelligent Testing"
              description="We create useful test cases catered to identify septic storage problems, memory leaks, and security vulnerabilities. We used a variety of testing technologies and tools that best fit your project."
              imageText="Intelligent Testing"
              imageUrl={APP_IMAGES.codeReviews}
            />
            <Card
              title="Project Delivery"
              description="Beyond development, we set various endpoints where at each point, we deploy the completed project in several phases to reduce reiteration and ensure smooth deployment promptly as promised."
              imageText="Project Delivery"
              imageUrl={APP_IMAGES.standupWeekly}
            />
          </div>
        </div>
      </div>
    </section>
  );
  const mobile = (
    <section className="w-full block lg:hidden mt-12 sm:mt-14 md:mt-20">
      <div className="w-full sm:w-[90%] sm:mx-auto px-6">
          <h2 className="font-semibold text-center text-2xl sm:text-4xl sm:leading-10 sm:mb-16 mb-10">
            Software Development Process
          </h2>
        <div className="grid sm:grid-cols-2 gap-8 sm:gap-10">
          <Card
            title="Assemble the right team"
            description="We pick the right expertise, domain knowledge, and willingness to adapt and evolve to your project requirement."
            imageText="Assemble the right team"
            imageUrl={APP_IMAGES.assemble}
          />
          <Card
            title="Development methodology planning"
            description="Choose the suitable methodology that empowers the development process to be conducted at its best. Our team members follow sprint planning and agile techniques."
            imageText="Development methodology planning"
            imageUrl={APP_IMAGES.sprintPlanning}
          />
          <Card
            title="Architecture creation"
            description="We handle all architecture evolution requirements ranging from UI/UX enhancement, decoupling tightly packed features by migrating to microservices or modularizing the existing architecture."
            imageText="Architecture creation"
            imageUrl={APP_IMAGES.techarchitechture}
          />

          <Card
            title="Periodical reviews"
            description="We conduct timely reviews to squash any foreseeable concern, present weekly demos, and standups to address any existing bottlenecks to ensure everyone is on the same page."
            imageText="Periodical reviews"
            imageUrl={APP_IMAGES.iterativeDelivery}
          />
          <Card
            title="Intelligent Testing"
            description="We create useful test cases catered to identify septic storage problems, memory leaks, and security vulnerabilities. We used a variety of testing technologies and tools that best fit your project."
            imageText="Intelligent Testing"
            imageUrl={APP_IMAGES.codeReviews}
          />
          <Card
            title="Project Delivery"
            description="Beyond development, we set various endpoints where at each point, we deploy the completed project in several phases to reduce reiteration and ensure smooth deployment promptly as promised."
            imageText="Project Delivery"
            imageUrl={APP_IMAGES.standupWeekly}
          />
        </div>
      </div>
    </section>
  );

  return (
    <>
      {desktop}
      {mobile}
    </>
  );
};

export default BitverxWork;
