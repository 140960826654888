import React from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image';

const Card = ({ title, description, imageUrl, imageText, className }) => {
  return (
    <div
      className={`flex flex-1 flex-col items-center rounded-lg basis-1/3 sm:h-[436px] shadow-lg p-6 lg:p-8 xl:p-10  ${className}`}
    >
      <div className="mb-6">
        <Image src={imageUrl} alt={imageText} width={'auto'} height={'auto'} className='text-center' />
      </div>
      <h3 className="mb-2 sm:mb-4 text-center text-base font-bold">{title}</h3>
      <p className="text-sm leading-6 md:text-lg text-center text-gray-dark">{description}</p>
    </div>
  );
};

Card.propsTypes = {
  title: PropTypes.string,
  discriptions: PropTypes.string,
  imageText: PropTypes.string,
  className: PropTypes.string
};
export default Card;
